<template>
  <div class="notice">
      <div class="cont">
        <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="getDataList" offset="10">
          <template v-for="item in dataList">
            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='村庄简介'" class="van-cell_1">
              <div class="item">
                <p>{{item.title}}</p>
                <p style="font-size: 14px;opacity: .6">Introduction to the village</p>
                <div class="line"></div>
                <img src="@/assets/img/wisdom_1.png" alt="" />
              </div>
            </van-cell>
          </template>
          <template v-for="item in dataList">
            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='特色品牌'" class="van-cell_1 van-cell_2">
              <div class="item">
                <p>{{item.title}}</p>
                <p style="font-size: 14px;opacity: .6">Featured brands</p>
                <div class="line"></div>
                <img src="@/assets/img/wisdom_2.png" alt="" />
              </div>
            </van-cell>
          </template>
          <template v-for="item in dataList">
            <van-cell :key="item.id" @click="getInfo(item.id)" v-if="item.title=='政策宣传'" class="van-cell_1 van-cell_3">
              <div class="item">
                <p>{{item.title}}</p>
                <p style="font-size: 14px;opacity: .6">Policy advocacy</p>
                <div class="line"></div>
                <img src="@/assets/img/policy.png" alt="" style="width: 80px;height: 90px;margin-right: 10px"/>
              </div>
            </van-cell>
          </template>

        </van-list>
        <van-empty description="没有数据哦" v-if="dataList.length < 1"/>
      </div>
<!--        <div class="cont">-->
<!--          <van-cell class="top" @click="getInfo">-->
<!--            <h3>村庄简介</h3>-->
<!--            <p>Introduction to the village</p>-->
<!--            <div class="line"></div>-->
<!--            <img src="@/assets/img/wisdom_1.png" alt="" />-->
<!--          </van-cell>-->
<!--          <van-cell class="top middle" @click="getInfo">-->
<!--            <h3>特色品牌</h3>-->
<!--            <p>Featured brands</p>-->
<!--            <div class="line"></div>-->
<!--            <img src="@/assets/img/wisdom_2.png" alt="" />-->
<!--          </van-cell>-->
<!--          <van-cell class="top bottom" @click="getInfo">-->
<!--            <h3>政策宣传</h3>-->
<!--            <p>Policy advocacy</p>-->
<!--            <div class="line"></div>-->
<!--            <img src="@/assets/img/wisdom_3.png" alt="" />-->
<!--          </van-cell>-->
<!--        </div>-->
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
     data() {
        return {
        searchValue: '',
        loading: false,
        finished: false,
        limit: 10,
        page: 0,
        dataList: [],
        };
     },
    methods: {
        ...mapMutations,
        getInfo (id) {
            this.$store.commit('setNoticeId', id)
            this.$router.push('/wisdom-info')
        },
        getDataList () {
            this.page++
            this.$toast.loading({duration: 0,message: '加载中...',forbidClick: true,});
            this.$http({
                url: this.$http.adornUrl('/wxapp/commonApp/notice/list'),
                method: 'post',
                params: this.$http.adornParams({
                    page: this.page,
                    limit: this.limit,
                    title: this.searchValue,  //搜索条件
                    type: '建强党建',        //活动类型
                    orgId: this.$orgId
                })
                }).then(({data})=> {
                    if (data.code == 0) {
                        if (this.dataList.length == data.page.totalCount) {
                           this.finished = true
                       }
                      this.dataList = this.dataList.concat(data.page.list)
                      // 加载状态结束
                      this.dataList.sort(function(a,b){return a.createTime < b.createTime ? 1 : -1})
                      this.loading = false;
                      this.$toast.clear()
                    }
                    this.$toast.clear()
            },err=> {this.$toast.clear()})
        // 数据全部加载完成
        },
        onSearch(val) {
            this.page = 0
            this.dataList = []
            this.getDataList()
        },
        onCancel() {
            this.page = 0
            this.dataList = []
            this.searchValue = ''
            this.getDataList()
        },
    },
    created () {
      this.getDataList()
    }
}
</script>

<style lang="scss" scoped>
.notice {
  .cont {
    padding: 20px;
    background-color: #f5f5f5;
    .van-cell_1 {
      position: relative;
      height: 240px;
      border-radius: 10px;
      background-image: linear-gradient(to left,#7969EE 55px, #9188FF);
      margin-top: 15px;
    }
    .van-cell_2 {
      background-image: linear-gradient(to left,#F94646 55px, #FF7B7B);
    }
    .van-cell_3 {
      background-image: linear-gradient(to left,#FF872D 55px, #FFB278);
    }
    .item {
      p {
        white-space: nowrap;      /*超出的空白区域不换行*/
        overflow: hidden;         /*超出隐藏*/
        text-overflow: ellipsis;  /*文本超出显示省略号*/
        width: 550px;
        margin: 10px 0;
        font-size: 34px;
        color: #fff;
      }
      span {
        color: #666;
      }
      .line {
        width: 50px;
        height: 8px;
        margin-top: 60px;
        border-radius: 4px;
        background-color: #fff;
      }
      img {
        position: absolute;
        bottom: -15px;
        right: -20px;
        height: 90%;
      }
    }
  }
}
//.notice {
//    padding-top: 100px;
//    .cont {
//        //padding: 20px;
//        background-color: #f5f5f5;
//        .top {
//            position: relative;
//            height: 240px;
//            margin-top: 15px;
//            border-radius: 10px;
//            background-image: linear-gradient(to left,#7969EE 55px, #9188FF);
//          h3 {
//            font-size: 40px;
//            font-weight: 400;
//            font-family: PingFang-SC-Bold;
//            color: #fff;
//            margin: 10px;
//          }
//          p {
//            color: #fff;
//            opacity: 0.4;
//            margin: 0;
//          }
//          .line {
//            width: 50px;
//            height: 8px;
//            margin-top: 60px;
//            border-radius: 4px;
//            background-color: #fff;
//          }
//          img {
//            position: absolute;
//            bottom: -15px;
//            right: -25px;
//            height: 100%;
//          }
//        }
//      .middle {
//        background-image: linear-gradient(to left,#F94646 55px, #FF7B7B);
//      }
//      .bottom {
//        background-image: linear-gradient(to left,#FF872D 55px, #FFB278);
//      }
//        .item {
//            p {
//                margin: 10px 0;
//                font-size: 30px;
//            }
//            span {
//                color: #666;
//            }
//        }
//    }
//}
</style>
